import React, { useState } from "react";
import { Box, Button, Typography, styled } from "@mui/material";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import moment from "moment";
import CancelSubscriptionDialog from "components/Dialogs/CancelSubscriptionDialog";
import { useOrg } from "pages/WorkspaceSettings";
import { paidAddons } from "utils/plans";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  border: `1px solid ${theme.grey.light}`,
  borderRadius: "4px",
  padding: "0.5rem 1rem",
  "& .color-border": {
    height: "50px",
    width: "5px",
    borderRadius: "50px",
    backgroundColor: theme.palette.warning.dark,
  },
  "& .actions": {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    "& .period": {
      padding: "0.5rem",
      border: "1px solid",
      borderRadius: "12px",
      borderColor: theme.palette.warning.dark,
      backgroundColor: `${theme.palette.warning.main}10`,
    },
  },
}));

const Addon: React.FC<{
  addon: any;
}> = ({ addon }) => {
  const { org: currentOrg } = useOrg();
  const currentAddon = paidAddons.find(
    (ad) => ad.lookup_key === addon.lookup_key
  );
  const { at_period_end, quantity, period_end, subscription_id } = addon;
  const [cancelPlanDialog, setCancelPlanDialog] = useState<boolean>(false);

  if (!currentAddon) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <Box className="color-border" />
        <Box>
          <Typography variant="h6">{currentAddon.name}</Typography>
          <Typography variant="body1" color="textSecondary">
            ${(quantity * currentAddon.price).toFixed(2)}
            /month {currentAddon.name === "Storage" && `for ${quantity} GB`}
          </Typography>
        </Box>
        <Box className="actions">
          {at_period_end === "cancel" ? (
            <Typography fontSize="small" className="period">
              Expiring: {moment(period_end).format("MMMM D, YYYY, hh:mm:ss A")}
            </Typography>
          ) : (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => {
                setCancelPlanDialog(true);
              }}
              startIcon={<ClearOutlinedIcon fontSize="small" />}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Wrapper>
      {cancelPlanDialog && (
        <CancelSubscriptionDialog
          open={cancelPlanDialog}
          organization={currentOrg}
          subscriptionId={subscription_id}
          isAddon
          close={() => {
            setCancelPlanDialog(false);
          }}
        />
      )}
    </>
  );
};

export default Addon;
