import React, { useMemo } from "react";
import { Box, styled } from "@mui/material";
import { useOrg } from "pages/WorkspaceSettings";
import { useOrganizationUsage } from "api/organizationService";
import { addonKeys } from "utils/plans";
import Plan from "./Plan";
import Addon from "./Addon";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

const defaultFreePlan = {
  lookup_key: "freeplan",
  at_period_end: "",
  period_end: "",
  next_key: undefined,
  subscription_id: "",
};

const WorkspacePlan: React.FC = () => {
  const { org: currentOrg } = useOrg();
  const { organizationUsage } = useOrganizationUsage(currentOrg.id);

  const { currentPlan, addonsToShow } = useMemo(() => {
    if (!organizationUsage || organizationUsage?.plans?.length === 0) {
      return {
        currentPlan: defaultFreePlan,
        addonsToShow: [],
      };
    }

    const subscriptionPlans = organizationUsage.plans.filter(
      (p) => !addonKeys.includes(p.lookup_key)
    );

    return {
      currentPlan: subscriptionPlans[0] || defaultFreePlan,
      addonsToShow: organizationUsage.plans.filter((p) =>
        addonKeys.includes(p.lookup_key)
      ),
    };
  }, [organizationUsage]);

  return (
    <Wrapper>
      <Plan
        lookupKey={currentPlan?.lookup_key}
        canCancel={
          currentPlan &&
          currentPlan.lookup_key !== "freeplan" &&
          currentPlan.at_period_end !== "cancel" &&
          currentPlan.at_period_end !== "change"
        }
        isYearly={currentPlan?.lookup_key.includes("yearly")}
        isCanceled={
          currentPlan &&
          (currentPlan.at_period_end === "cancel" ||
            currentPlan.at_period_end === "change")
        }
        cancelTime={currentPlan?.period_end}
        subscriptionId={currentPlan?.subscription_id}
      />
      {((currentPlan?.at_period_end === "change" && currentPlan.next_key) ||
        (currentPlan?.at_period_end === "cancel" && !currentPlan.next_key)) && (
        <Plan
          lookupKey={currentPlan?.next_key || "freeplan"}
          canCancel={!!currentPlan?.next_key}
          isYearly={
            !!currentPlan?.next_key && currentPlan?.next_key.includes("yearly")
          }
          subscriptionId={currentPlan?.subscription_id}
          showNext
          nextTime={currentPlan?.period_end}
        />
      )}
      {addonsToShow?.map((addon: any) => (
        <Addon key={addon.subscription_id} addon={addon} />
      ))}
    </Wrapper>
  );
};

export default WorkspacePlan;
