export const WWW_URL = "https://app.delaplex.digital";
export const SUPPORT_URL = {
  type: "email",
  link: "mailto:info@delaplex.digital",
};
export const COMPANY_WEBSITE = "https://delaplex.com/";

export const PROD_HOSTNAME = "app.delaplex.digital";
export const DEV_HOSTNAME = "app.delaplex.digital";

export const PROD_URL = `https://${PROD_HOSTNAME}`;
export const DEV_URL = `https://${DEV_HOSTNAME}`;

export const LOGO_SRC = "/img/logo/delaplex-logo.png";
export const SMALL_LOGO_SRC = "/img/logo/delaplex-logo-small.png";

export const APP_TITLE = "delaPlex";

export const companyFeatures = {
  isWhiteLabel: true,
  enablePetalRoutes: false,
  enableSecondaryApps: false,
  workspace: {
    singleWorkspace: false,
  },
  auth: {
    enableSignUp: true,
    enableGoogleAuth: false,
    enableForgotPassword: true,
  },
  settings: {
    enableSettingsPage: true,
    canUpgradePlans: true,
    canLeaveWorkspace: true,
  },
  app: {
    canSeeOnboarding: true,
    isTrialExists: true,
    canInvite: true,
    canCreateOrg: true,
    showDocumentation: false,
    guests: {
      canSeeNotifications: true,
      enableDocumentViewer: true,
      enableDocumentActions: true,
      enableLookUpTab: true,
    },
  },
};

export const companySettings: {
  defaultAIModel: "gpt-4o-mini" | "gpt-4o";
  defaultMultiDocChatModel: "gpt-4o-mini-16k" | "gpt-4o";
} = {
  defaultAIModel: "gpt-4o-mini",
  defaultMultiDocChatModel: "gpt-4o-mini-16k",
};
